import { T_TopupRules, T_WithdrawalRules } from "@opr-finance/utils";

export const withdrawalRules: T_WithdrawalRules = {
    minWithdrawal: 200,
    overdueDays: 7,
    accountState: ["OPEN", "PENDING"],
    blockedStatus: false,
};

export const topupRules: T_TopupRules = {
    creditLimit: 30000,
    accountState: ["OPEN"],
    overdueDays: 7,
    paidInvoices: 1,
    blockedStatus: false,
};
