import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-fi-topup-page-title",
    },
    alertTextRequired: {
        id: "flex-online-fi-topup-text-alert-required-field",
    },
    dropdownChoose: {
        id: "flex-online-fi-topup-page-dropdown-menu-choose",
    },
    heading: {
        id: "flex-online-fi-topup-page-heading",
    },
    Subheading1: {
        id: "flex-online-fi-topup-page-subheading-1",
    },
    Subheading2: {
        id: "flex-online-fi-topup-page-subheading-2",
    },
    Subheading3: {
        id: "flex-online-fi-topup-page-subheading-3",
    },
    topTextInfoLongText: {
        id: "flex-online-fi-topup-page-long-info",
    },
    topTextInfo2: {
        id: "flex-online-fi-topup-page-top-info-2",
    },
    textCreditLimit: {
        id: "flex-online-fi-topup-page-text-credit-limit",
    },
    textNewCredit: {
        id: "flex-online-fi-topup-page-text-new-credit-limit",
    },
    companyName: {
        id: "flex-online-fi-topup-page-text-company-name",
    },
    businessID: {
        id: "flex-online-fi-topup-page-text-company-businessID",
    },
    monhtlySale: {
        id: "flex-online-fi-topup-page-text-monthly-sales",
    },
    emailText: {
        id: "flex-online-fi-topup-page-text-email",
    },
    placeholder: {
        id: "flex-online-fi-topup-page-placeholder-text",
    },
    guaranteeName: {
        id: "flex-online-fi-topup-page-text-guarantee-name",
    },
    guaranteeSSN: {
        id: "flex-online-fi-topup-page-text-guarantee-SSN",
    },
    guaranteePhone: {
        id: "flex-online-fi-topup-page-text-guarantee-phone",
    },
    campaignCode: {
        id: "flex-online-fi-topup-page-campaign-code",
    },
    checkboxInfo1: {
        id: "flex-online-fi-topup-page-checkbox-info-1",
    },
    checkboxInfo2: {
        id: "flex-online-fi-topup-page-checkbox-info-2",
    },
    checkboxInfo2Modal: {
        id: "flex-online-fi-topup-page-checkbox-info-2-modal",
    },
    checkboxInfo2Link: {
        id: "flex-online-fi-topup-page-checkbox-info-2-link",
    },
    checkboxInfo2LinkText: {
        id: "flex-online-fi-topup-page-checkbox-info-2-text",
    },
    buttontextPreview: {
        id: "flex-online-fi-topup-page-button-text-preview",
    },
    buttonTextSend: {
        id: "flex-online-fi-topup-page-button-text-send",
    },
    buttonTextGoback: {
        id: "flex-online-fi-topup-page-button-text-goBack",
    },
    alertText: {
        id: "flex-online-fi-topup-page-alert-text",
    },
    sentApplicationInfo1: {
        id: "flex-online-fi-topup-page-sent-application-info-1",
    },
    sentApplicationInfo2: {
        id: "flex-online-fi-topup-page-sent-application-info-2",
    },
    sentApplicationInfo3: {
        id: "flex-online-fi-topup-page-sent-application-info-3",
    },
    customerServiceLink: {
        id: "flex-online-fi-email-customerService-link",
    },
    customerServiceLinkText: {
        id: "flex-online-fi-email-customerService-text",
    },
    sentModalTitle: {
        id: "flex-online-fi-topup-page-sent-application-modal-title",
    },
    sentModalInfo1: {
        id: "flex-online-fi-topup-page-sent-application-modal-info1",
    },
    sentModalInfo2: {
        id: "flex-online-fi-topup-page-sent-application-modal-info2",
    },
    sentModalInfo3: {
        id: "flex-online-fi-topup-page-sent-application-modal-info3",
    },
});
